import { createContext } from 'react';
import { defaultRemoteControlState, ModifiedProcess, RemoteControlState } from '../../contexts/RemoteControlState';
import { Patient, PatientSettingsData } from '../../data/Patient';
import { RemoteClient } from "../../data/RemoteClient";
import { TrainingData } from './TrainingData';

interface RemoteControlContextType {
	userID: string | null;
	state: RemoteControlState;
	currentTraining: TrainingData | null;
	eventTarget: EventTarget;

	setPatientSettings: (settings: PatientSettingsData) => void;
	commitPatientSettings: () => void;
	setProcesses: (processes: ModifiedProcess[]) => void;

	clients: RemoteClient[];
	stream: MediaStream | undefined;
	connectToClient: (client: RemoteClient) => Promise<void>;
	sendMessage: (message: any) => void;
	setRemoteUser: (remoteUserID: string | null, patient: Patient | null) => void;
	refresh: () => void;
	disconnect: () => void;
}

export const RemoteControlContext = createContext<RemoteControlContextType>({
	userID: null,
	state: defaultRemoteControlState,
	currentTraining: null,

	eventTarget: new EventTarget(),

	setPatientSettings: (settings: PatientSettingsData) => { },
	commitPatientSettings: () => { },
	setProcesses: (processes: ModifiedProcess[]) => { },

	clients: [],
	stream: undefined,
	connectToClient: (client: RemoteClient) => Promise.resolve(),
	sendMessage: (message: any) => { },
	setRemoteUser: (remoteUserID: string | null, patient: Patient | null) => { },
	refresh: () => { },
	disconnect: () => { }
});
