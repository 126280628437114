import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Typography } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import Paper from '@mui/material/Paper';
import { CentreInvite, InvitationStatus, PatientInvite } from "../center/invite";
import { AvatarCustom } from "./Avatar";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { PremiumUser, Role, roleToText } from "../login/PremiumUser";
import { getStripePortal, rejectCentreInvitation, subscribeToAllCentreInvites, subscribeToAllPatientInvites } from "../../adapters/Firebase";
import UserLogo from "./UserLogo";
import SentCentreInvites from "../center/SentCentreInvites";
import ReceivedCentreInvites from "../center/ReceivedCentreInvites";
import SendPatientInvites from "../patient/SendPatientInvites";
import ReceivedPatientInvites from "../patient/ReceivedPatientInvites";
import AlertDialog from "../../components/atoms/Popup";
import VRAccess from "./VRAccess";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PricingPage from "../buying/PricingPage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationContext";

interface UserPageProps {
	user: PremiumUser,
	onLicenseUpdated: () => void,
	onLogout: () => void,
}

export function UserPage(props: UserPageProps) {
	const { translate } = useTranslation();
	const [leaveWarning, setLeaveWarning] = useState<boolean>(false);
	const [center, setCenter] = useState<CentreInvite | null | undefined>(undefined);
	const [centreInvites, setCentreInvites] = useState<CentreInvite[]>([]);
	const [patientInvites, setPatientInvites] = useState<PatientInvite[]>([]);

	const userid = props.user.uid;
	const onLicenseUpdated = props.onLicenseUpdated;
	const isAdmin = props.user.isAdmin;

	const navigate = useNavigate();

	useEffect(() => {
		const unsubscribe = subscribeToAllCentreInvites(
			userid, "invitesReceived",
			(invitations: CentreInvite[]) => {
				const invitation = invitations.find((invitation) => invitation.status === InvitationStatus.accepted);
				const prevCenter = center;
				if (prevCenter !== undefined) {
					if ((prevCenter !== null && invitation === null)
						|| (prevCenter === null && invitation !== null)) {
							onLicenseUpdated();
					}
				}
				setCentreInvites(() => invitations);
				setCenter(() => invitation ?? null);
			});
		return unsubscribe;
	}, [userid, onLicenseUpdated, center]);

	useEffect(() => {
		const unsubscribe = subscribeToAllPatientInvites(
			props.user.uid, "patientInvitesReceived",
			(invitations: PatientInvite[]) => {
				setPatientInvites(() => invitations);
			});
		return unsubscribe;
	}, [props.user.uid]);

	const hasLicense = props.user.role !== Role.None;
	const role = props.user ? props.user.role : Role.None;

	async function openLicenseManager() {
		const { data } = await getStripePortal()({
			returnUrl: window.location.origin,
			locale: "auto",
		});
		window.location.assign(data.url);
	}

	const handleSuscription = () => {
		openLicenseManager();
	}

	function handleLeaveMyCenter() {
		if (!!center) {
			rejectCentreInvitation(center.centreID);
		}
		setLeaveWarning(false);
	}

	function handleAdminPage() {
		navigate("/user/admin/");
	}

	return (
		<Box gap={2}>
			<Typography variant="h3" noWrap>
				{translate("[userpage_title]")}
			</Typography>
			<br />
			<Box display="flex" justifyContent="center" justifySelf="center">
				<UserLogo role={props.user ? props.user.role : Role.None} width={300} />
			</Box>

			<Box display="flex" flexDirection="column" alignItems="center">
				<Typography
					variant="subtitle1"
					style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
				>
					<strong>{translate(roleToText(props.user.role))}</strong>
				</Typography>
				<Typography
					variant="body2"
					style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
				>
					{props.user.email}
				</Typography>

				{!!isAdmin &&
					<Button style={{ fontWeight: "bold" }} onClick={handleAdminPage}>
						{translate("[admin_page]")}
					</Button>
				}

				{!!hasLicense &&
					<Button style={{ fontWeight: "bold" }} onClick={handleSuscription}>
						{translate("[usermenu_subscription]")} →
					</Button>
				}

				<Button style={{ fontWeight: "bold" }}
					onClick={props.onLogout}>
					{translate("[usermenu_logout]")}
				</Button>
			</Box>

			{!!center &&
				<Box marginBottom={2}>
					<Typography variant="h5" gutterBottom noWrap>
						{translate("[mycenter_mycenter]")}
					</Typography>
					<Typography variant="body2" gutterBottom>
						{translate("[mycenter_mycenter_description]")}
					</Typography>
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						<Box gap={2} display="flex" flexDirection="row" alignItems="center">
							<AvatarCustom id={center.centreID}>
								<LocalHospitalIcon style={{ margin: "auto" }} color="inherit" />
							</AvatarCustom>

							<Typography variant="body1" style={{ flexGrow: 1 }}>
								{center.centreEmail}
							</Typography>

							<IconButton aria-label="accept"
								color="secondary"
								onClick={() => setLeaveWarning(true)}>
								<LogoutIcon />
							</IconButton>
						</Box>
					</Paper>
				</Box>
			}

			{!center && role === Role.None && centreInvites.length > 0 &&
				<ReceivedCentreInvites invitations={centreInvites} />
			}

			{role === Role.Patient && patientInvites.length > 0 &&
				<ReceivedPatientInvites invitations={patientInvites} />
			}

			{role === Role.Centre &&
				<SentCentreInvites />
			}
			{(role === Role.Centre || role === Role.Therapist)  &&
				<SendPatientInvites />
			}

			<br />
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}>
					<Typography variant="h6">{translate("[vraccess_title]")}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<VRAccess />
				</AccordionDetails>
			</Accordion>
			<br />

			{!hasLicense &&
				<>
					<Typography variant="h3" gutterBottom>
						{translate("[pricing_buylicense]")}
					</Typography>
					<PricingPage hideTitle/>
				</>
			}

			<AlertDialog
				open={!!leaveWarning}
				title={translate("[mycenter_leavewarning_title]")}
				okTitle={translate("[mycenter_leave]")}
				onOk={handleLeaveMyCenter}
				cancelTitle={translate("[therapists_cancel]")}
				onCancel={() => setLeaveWarning(false)} >
				{translate("[mycenter_leavewarning_description]")}
			</AlertDialog>

		</Box>
	);
}

export default UserPage;
