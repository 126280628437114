import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "../../contexts/TranslationContext";
import DownloadIcon from '@mui/icons-material/Download';
import diswasherScreenshot from "../../media/landing/diswasher_screenshot.png";
import metaquestback from "../../media/landing/metaquestback.png";
import ReactMarkdown from "react-markdown";



function DownloadDemo() {
	const { translate } = useTranslation();
	const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

	return (
		<Box my={10}>
			<Typography variant="h2" gutterBottom>
				{translate("[landing_downloaddemo_title]")}
			</Typography>

			<Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} alignItems="center" gap={2} >
				<Box display="flex" flexDirection="column" gap={2} justifyContent="stretch">
					<Typography variant="subtitle1" component="div">
						<ReactMarkdown>
							{translate("[landing_downloaddemo_body1]")}
						</ReactMarkdown>
					</Typography>
					<Typography variant="body1" component="div">
						<ReactMarkdown>
							{translate("[landing_downloaddemo_body2]")}
						</ReactMarkdown>
					</Typography>
					<Button variant="contained" color="primary"
						href="https://www.meta.com/experiences/4144031432312694" target="_blank"
						startIcon={<DownloadIcon />} >
						{translate("[landing_downloaddemo_button]")}
					</Button>
        </Box>
        <Box
          style={{
            position: 'relative', // Make the parent a reference for absolute positioning
            width: '400px', // Define a fixed width
            height: '400px', // Define a fixed height for the box
            aspectRatio: '1/1'
          }}
        >
          <img
            src={diswasherScreenshot}
            alt="Diswasher exercise"
            style={{
              position: 'absolute', // Make this image positioned absolutely
              top: 0,
              left: 0,
              width: '100%', // Adjust to fill the parent box
              height: '100%', // Adjust to fill the parent box
              zIndex: 1, // Make sure it's at the correct layer
            }}
          />
          <img
            src={metaquestback}
            alt="Meta Quest"
            style={{
              position: 'absolute', // Make this image positioned absolutely as well
              top: -10,
              right: -70,
              width: '60%', // Adjust to fill the parent box
              height: '60%', // Adjust to fill the parent box
              zIndex: 2, // This image will be above the first one
            }}
          />
        </Box>


			</Box >
		</Box>
	)
}

export default DownloadDemo;
