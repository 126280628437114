/* eslint-disable react/jsx-no-undef */
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import ReactMarkdown from 'react-markdown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UserLogo from '../users/UserLogo';
import { Role } from '../login/PremiumUser';
import { useTranslation } from '../../contexts/TranslationContext';
import { MailOutline } from '@mui/icons-material';



interface LicenseProductProps {
	title: string;
	description: string;
	price: string;
	role: Role;
	features: string[];
	directPrice?: boolean;
	onBuy: () => void;
}

function LicenseProduct(props: LicenseProductProps) {
	const { translate } = useTranslation();
	return (
		<Card elevation={3} >
			<CardHeader
				title={
					<Typography variant="h4" textAlign="center">
						{translate(props.title)}
					</Typography>
				}
			>
			</CardHeader>
			<CardMedia>
				<Box display="flex" justifyContent="center" alignItems="center" height="120px" >
					<UserLogo role={props.role} width="70%" />
				</Box>
			</CardMedia>
			<CardContent>
				<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100px">
					<Typography variant="h5" textAlign="center" component="div">
						<ReactMarkdown>
							{translate(props.price)}
						</ReactMarkdown>
					</Typography>
					{props.directPrice &&
						<Typography variant="caption" textAlign="center">
							{translate("[pricing_taxincluded]")}
						</Typography>
					}
				</Box>

				<Typography variant="body1" style={{ minHeight: "120px" }} component="div">
					<ReactMarkdown>
						{translate(props.description)}
					</ReactMarkdown>
				</Typography>

					<Button variant="contained" color="primary" fullWidth
						onClick={props.onBuy}
						startIcon={props.directPrice? <ShoppingCartIcon /> : <MailOutline/>}>
						{translate(props.directPrice?"[pricing_buylicense]" : "[pricing_quote]")}
					</Button>
				

				<Box >
					<List
						sx={{
							pl: 0,
							'& .MuiListItem-root': {
								display: 'flex',
								alignItems: 'center',
							},
						}}>
						{props.features.map((feature, index) => (
							<ListItem key={index}>
								<ListItemIcon>
									<CheckCircleIcon color="secondary" />
								</ListItemIcon>
								<ReactMarkdown>
									{translate(feature)}
								</ReactMarkdown>
							</ListItem>
						))}
					</List>
				</Box>
			</CardContent>
		</Card>
	);
}

export default LicenseProduct;
