import { Box, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';


export interface AlertProps {
	open: boolean;
	title: string;
	okTitle?: string;
	cancelTitle?: string;
	onOk?: () => void;
	onCancel?: () => void;
	children: any;
	header?: any;
	onClose?: () => void;
}

export default function AlertDialog(props: AlertProps) {

	const handleCancel = () => {
		if (props.onCancel) {
			props.onCancel();
		}
	};
	const handleOk = () => {
		if (props.onOk) {
			props.onOk();
		}
	};

	const handleClose = () => {
		if (props.onClose) {
			props.onClose();
		}
		else if (props.onCancel) {
			props.onCancel();
		}
	};

	return (
		<div>
			<Dialog
				open={props.open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="md"
				fullWidth
			>
				<DialogTitle id="alert-dialog-title">
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h6" marginTop={1}>
							{props.title}
						</Typography>
						<IconButton
								edge="end"
								color="inherit"
								onClick={handleClose}
								aria-label="close">
								<CloseIcon />
							</IconButton>
					</Box>
					{props.header}
				</DialogTitle>
				<DialogContent>
					{props.children}
				</DialogContent>
				<DialogActions>
					{props.cancelTitle && <Button onClick={handleCancel} autoFocus>{props.cancelTitle}</Button>}
					{props.okTitle && <Button onClick={handleOk}>{props.okTitle}</Button>}
				</DialogActions>
			</Dialog>
		</div>
	);
}