import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import translationFiles from "../l10n/translationFiles.json";

interface TranslationContextType {
	translate: (key: string) => string;
	translateWithObject: (key: string) => string;
	changeLanguage: (language: string) => void;
	currentLanguage: string;
}

interface TranslationProviderProps {
	children: ReactNode;
}

interface Translation {
	general: { [key: string]: string };
	items: any;
}

const tagRegex = /\[[^\]]*\]/g
const itemRegex = /{(?<objectKey>[\w_]+)\s*(?:color=\[(?<color>[\w_]+)\])?\s*(?:size=\[(?<size>[\w_]+)\])?\s*(?:laterality=\[(?<laterality>[\w_]+)\])?\s*(?:handedness=\[(?<handedness>[\w_]+)\])?}/gi;
const blanksRegex = /\s+/g;

const loadTranslations = async (lang: string): Promise<Translation> => {
	try {

		const itemsFileName = translationFiles.items;
		const fileNames = [...translationFiles.common, ...translationFiles.web];
		const items = await import(`../l10n/${lang}/${itemsFileName}.json`);
		const translationsArray = await Promise.all(
			fileNames.map((file) => import(`../l10n/${lang}/${file}.json`))
		);
		const general = translationsArray.reduce((acc, translation) => {
			return { ...acc, ...translation };
		}, {});

		return {
			general,
			items
		};
	} catch (error) {
		console.error('Error loading translations:', error);
		return {
			general: {},
			items: {}
		};
	}
};

const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

function TranslationProvider(props: TranslationProviderProps) {
	const [language, setLanguage] = useState<string>("es");
	const [translations, setTranslations] = useState<Translation>({
		general: {},
		items: {}
	});

	useEffect(() => {
		loadTranslations(language).then((loadedTranslations) => {
			setTranslations(loadedTranslations);
		});
	}, [language]);

	const changeLanguage = (newLanguage: string) => {
		setLanguage(newLanguage);
	};

	function translateItems(text: string): string {

		const getItemText = ((groups: { [key: string]: string }): string => {

			const items = translations.items;
			const objectTitleFormat = items.object_title_format;
			const objectKey = groups["objectKey"];
			const objectNode = items[objectKey];
			if (!objectNode) {
				return "<Missing/>";
			}

			const concordanceKey = objectNode["concordance"];
			const colorKey = groups["color"];
			const sizeKey = groups["size"];
			const lateralityKey = groups["laterality"];
			const handednessKey = groups["handedness"];

			const objectName = objectNode.name;
			const color = items[colorKey] ? items[colorKey][concordanceKey] : "";
			const size = items[sizeKey] ? items[sizeKey][concordanceKey] : "";
			const article = items["article"][concordanceKey] ?? "";
			const laterality = items[lateralityKey] ?? "";
			const handedness = items[handednessKey] ?? "";

			let objectTitle = objectTitleFormat
				.replace('{0}', article)
				.replace('{1}', objectName)
				.replace('{2}', color)
				.replace('{3}', size)
				.replace('{4}', laterality)
				.replace('{5}', handedness);

			objectTitle = objectTitle.replace(blanksRegex, " ");
			objectTitle = objectTitle.trim();
			return objectTitle;
		});

		let result = text;
		let match;
		while (!!(match = itemRegex.exec(text))) {
			const groups = match.groups;
			if (groups) {
				const itemText = getItemText(groups);
				result = result.replace(match[0], itemText);
			}
		}

		return result;
	}

	function translateText(text: string): string {
		const matches = text.match(tagRegex);
		matches?.forEach(match => {
			const key = match.substring(1, match.length - 1);
			const translation = translations.general[key];
			if (!!translation) {
				text = text.replace(match, translation);
			}
		});
		return text;
	}

	function translateTags(text: string): string {
		return text
			.replace(/<align=center>(.*?)<\/align>/g, "$1")
			.replace(/<align=center>(.*?)/g, "$1")
			.replace(/<b>(.*?)<\/b>/g, "**$1**")
			.replace(/<s>(.*?)<\/s>/g, "~~$1~~")
			.replace(/<i>(.*?)<\/i>/g, "*$1*")
			.replace(/\n/g, "  \n")
	}

	function translateWithObject(text: string): string {
		text = translateItems(text);
		text = translateText(text);
		text = translateTags(text);
		return text;
	}

	function translate(text: string): string {
		if (!text) {
			return "";
		}
		text = translateText(text);
		text = translateTags(text);
		return text;
	};

	return (
		<TranslationContext.Provider value={{
			translate,
			translateWithObject,
			changeLanguage,
			currentLanguage: language
		}}>
			{props.children}
		</TranslationContext.Provider>);
}

const useTranslation = () => {
	const context = useContext(TranslationContext);
	if (!context) {
		throw new Error('useTranslation must be used within a TranslationProvider');
	}
	return context;
};

export { TranslationProvider, TranslationContext, useTranslation };