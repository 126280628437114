import { Box, Button, SvgIcon, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import { ReactComponent as CheckIcon } from '../../../media/check.svg';
import { ReactComponent as CancelIcon } from '../../../media/cancel.svg';
import { ReactComponent as StarIcon } from '../../../media/star.svg';
import { useTranslation } from "../../../contexts/TranslationContext";

export enum PointAtMode {
	Follow,
	Drag,
	Look,
	Yay,
	Nay
}

interface MousePosition {
	x: number;
	y: number;
}

const dragThreshold = 0.1;
const draggingSpeed = 100;

interface VideoStreamProps {
	stream: MediaStream | null;
	isStreaming: boolean;
	onPointed: (x: number, y: number, mode: PointAtMode) => void;
}

function VideoStream(props: VideoStreamProps) {
	const { translate } = useTranslation();
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [pointAtMode, setPointAtMode] = useState<PointAtMode>(PointAtMode.Look);
	const [isDragging, setIsDragging] = useState(false);
	const [isFollowing, setIsFollowing] = useState(true);
	const [isDown, setIsDown] = useState(false);
	const [lastPosition, setLastPosition] = useState<MousePosition>({ x: 0, y: 0 });
	const [startPosition, setStartPosition] = useState<MousePosition>({ x: 0, y: 0 });

	const pointedEvent = props.onPointed;
	const theme = useTheme();

	function normalizePoint(x: number, y: number, element: HTMLVideoElement) {
		const rect = element.getBoundingClientRect();
		const normalizedX = (x - rect.left) / rect.width;
		const normalizedY = 1 - ((y - rect.top) / rect.height);

		return [ normalizedX, normalizedY];
	}


	function pointerClick(x: number, y: number, element: HTMLVideoElement) {
		const wasDragging = isDragging;
		setIsDragging(false);
		if (wasDragging) {
			return;
		}

		const [normalizedX, normalizedY] = normalizePoint(x, y, element);

		pointedEvent(normalizedX, normalizedY, pointAtMode);
	}

	function pointerDown(x: number, y: number, element: HTMLVideoElement) {
		const [normalizedX, normalizedY] = normalizePoint(x, y, element);
		setLastPosition({ x: normalizedX, y: normalizedY });
		setStartPosition({ x: normalizedX, y: normalizedY });
	}

	function pointerMove(x: number, y: number, element: HTMLVideoElement) {
		const [normalizedX, normalizedY] = normalizePoint(x, y, element);
		const deltaX = normalizedX - lastPosition.x;
		const deltaY = normalizedY - lastPosition.y;

		setLastPosition({ x: normalizedX, y: normalizedY });

		if (!isDragging) {
			if (Math.abs(startPosition.x - normalizedX) > dragThreshold
				|| Math.abs(startPosition.y - normalizedY) > dragThreshold) {
				setIsDragging(true);
			}
			else {
				return;
			}
		}

		pointedEvent(deltaY * draggingSpeed, -deltaX * draggingSpeed, PointAtMode.Drag);
	}

	const handlePointerDown = (e: React.MouseEvent<HTMLVideoElement, PointerEvent>) => {
		pointerDown(e.clientX, e.clientY, e.currentTarget);
		setIsDown(true);
	}
	const handlePointerMove = (e: React.MouseEvent<HTMLVideoElement, PointerEvent>) => {
		if (isDown) {
			pointerMove(e.clientX, e.clientY, e.currentTarget);
		}
	}
	const handlePointerOut = (e: React.MouseEvent<HTMLVideoElement, PointerEvent>) => {
		setIsDown(false);
	}

	const handlePointerUp = (e: React.MouseEvent<HTMLVideoElement, PointerEvent>) => {
		pointerClick(e.clientX, e.clientY, e.currentTarget);
		setIsDown(false);
	}

	const handleTouchDown = (e: React.TouchEvent<HTMLVideoElement>) => {
		if (e.touches.length > 0) {
			pointerDown(e.touches[0].clientX, e.touches[0].clientY, e.currentTarget);
		}
	}
	const handleTouchMove = (e: React.TouchEvent<HTMLVideoElement>) => {
		if (e.touches.length > 0) {
			pointerMove(e.touches[0].clientX, e.touches[0].clientY, e.currentTarget);
		}
	}
	const handleTouchEnd = (e: React.TouchEvent<HTMLVideoElement>) => {
		if (e.touches.length > 0) {
			pointerClick(e.touches[0].clientX, e.touches[0].clientY, e.currentTarget);
		}
		setIsDragging(false);
	}

	const handlePointAtModeChanged = (e: React.MouseEvent<HTMLElement>, newPointAtMode: PointAtMode | null) => {
		if (newPointAtMode !== null) {
			setPointAtMode(newPointAtMode);
		}
	}

	const handleFollowClick = useCallback(() => {
		setIsDragging(false);
		setIsFollowing(true);
		pointedEvent(0, 0, PointAtMode.Follow);
	}, [pointedEvent]);

	useEffect(() => {
		if (isDragging) {
			setIsFollowing(false);
		}
	}, [isDragging]);

	useEffect(() => {
		if (videoRef.current && props.stream) {
			videoRef.current.srcObject = props.stream;
		}
	}, [props.stream]);

	useEffect(() => {
		pointedEvent(0, 0, PointAtMode.Follow);
	}, []);

	return (
		<Box style={{
			position: 'relative',
			maxWidth: '1280px',
			width: '100%',
		}}>

			<video ref={videoRef} autoPlay playsInline
				style={{
					aspectRatio: '16 / 9',
					maxWidth: '100%',
					width: '100%',
				}}
				color="red"
				onTouchStartCapture={handleTouchDown}
				onTouchMoveCapture={handleTouchMove}
				onTouchEndCapture={handleTouchEnd}

				onMouseDown={handlePointerDown}
				onMouseMove={handlePointerMove}
				onMouseOut={handlePointerOut}
				onMouseUp={handlePointerUp}
			/>

			<Box style={{
				position: 'absolute',
				top: '10px',
				left: '10px',
				display: 'flex',
				flexDirection: 'column'
			}}
				gap={1}
			>
				<Typography color={theme.palette.background.paper} >
					{translate("[videostream_click]")}
				</Typography>
				<ToggleButtonGroup exclusive
					disabled={!props.isStreaming}
					color="primary"
					value={pointAtMode}
					style={{ backgroundColor: theme.palette.background.paper }}
					onChange={handlePointAtModeChanged}
				>
					<ToggleButton value={PointAtMode.Look}>
						<SvgIcon component={StarIcon}
							viewBox="0 0 64 64" />
					</ToggleButton>
					<ToggleButton value={PointAtMode.Yay}>
						<SvgIcon component={CheckIcon}
							viewBox="0 0 64 64" />
					</ToggleButton>
					<ToggleButton value={PointAtMode.Nay}>
						<SvgIcon component={CancelIcon}
							viewBox="0 0 64 64" />
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<Box style={{
				position: 'absolute',
				top: '95px',
				left: '10px',
			}}>
				{isFollowing ?
					<Typography color={theme.palette.background.paper} >
						{translate("[videostream_look]")}
					</Typography> :
					<Button variant="contained" color="primary"
						disabled={isFollowing || !props.isStreaming}
						onClick={handleFollowClick} >
						{translate("[videostream_follow]")}
					</Button>
				}
			</Box>
		</Box >
	);
};

export default VideoStream;