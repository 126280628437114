export enum InvitationStatus {
	pending = "pending",
	accepted = "accepted",
	cancelled = "cancelled",
}

export interface CentreInvite {
	centreID: string;
	therapistID: string;
	centreEmail: string;
	therapistEmail: string;
	status: InvitationStatus;
}

export interface PatientInvite {
	patientID: string;
	therapistID: string;
	patientEmail: string;
	therapistEmail: string;
	status: InvitationStatus;
}

export function inviteStatusToText(status: InvitationStatus): string {
	switch (status) {
		case InvitationStatus.pending:
			return "[therapist_status_pending]";
		case InvitationStatus.accepted:
			return "[therapist_status_accepted]";
		case InvitationStatus.cancelled:
			return "[therapist_status_rejected]";
	}
}